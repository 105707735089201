import React, { useCallback, useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { usePermissions, useRedirect, useTranslate } from 'react-admin';
import { enqueueSnackbar } from 'notistack';
import { Field, Form } from 'react-final-form';
import { Tooltip, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import crmSources from '@constants/crmSources';
import { INTEGRATIONS } from '@constants/routes';
import { basePermissions } from '@constants/permissions';
import { TEXT_BASE_ERROR } from '@constants/texts/common';

import { grayColor, greenColor, redColor } from '@constants/palette';

import { actions } from '@store/actions';

import iconDelete from '@assets/icons/delete.svg';

import Loading from '@common/Loading/Loading';
import Input from '@common/Input/Input';
import ReusableButton from '@common/Button/Button';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import { useHistory } from 'react-router-dom';
import PsaSyncDialog from '@components/Ticketing/Integrations/pages/updateCrm/dialogs/PsaSyncDialog';
import FormSelector from '../CrmInfo/FormSelector';

import {
  deleteIntegration,
  getById,
  getCrmImage,
  syncCompanies,
  syncUsers,
  updateIntegration,
  verifyCredentials,
} from '../../helpers';

import getValidation from '../CrmInfo/validators';
import getInitialValues from '../CrmInfo/initialValues';

import useStyles from './updateFormStyles';

const crmsWithSyncModal = [crmSources.ConnectWise.name];

const UpdateForm = ({ id, setCrmType }) => {
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [syncing, setSyncing] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [data, setData] = useState({});
  const [checkCredsColor, setCheckCredsColor] = useState(grayColor);
  const [openPsaSyncDialog, setOpenPsaSyncDialog] = useState(false);

  const classes = useStyles();
  const dispatch = useDispatch();
  const translate = useTranslate();
  const { permissions } = usePermissions();
  const { push } = useHistory();

  const getData = useCallback(() => getById(id), [id]);

  const allowUpdate = useMemo(
    () => permissions && permissions.integrations[basePermissions.update],
    [permissions],
  );

  const allowDelete = useMemo(
    () => permissions && permissions.integrations[basePermissions.delete],
    [permissions],
  );

  const submit = async values => {
    setProcessing(true);
    const payload = {
      crmType: data.crmType,
      ...values,
    };
    if (await updateIntegration(id, payload)) {
      enqueueSnackbar('Record updated', { variant: 'success' });
    } else {
      enqueueSnackbar(TEXT_BASE_ERROR, { variant: 'error' });
    }
    setProcessing(false);
  };

  const handleDeleteClick = () => setDeleteDialogOpen(true);

  const onCancelDeleteHandler = () => setDeleteDialogOpen(false);

  const onDeleteHandler = async () => {
    deleteIntegration(id)
      .then(() => {
        setDeleteDialogOpen(false);
        enqueueSnackbar('Integration was deleted', { variant: 'success' });
        push(INTEGRATIONS);
      })
      .catch(error => {
        enqueueSnackbar(error.error.message, { variant: 'error' });
      });
  };

  const syncCompaniesSubmitHandler = companyFilters => {
    setSyncing(true);
    syncCompanies(id, companyFilters)
      .then(() =>
        enqueueSnackbar('Successfully synchronized', { variant: 'success' }),
      )
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }))
      .finally(() => setSyncing(false));
  };

  const syncUsersHandler = () => {
    setSyncing(true);
    syncUsers(id)
      .then(() =>
        enqueueSnackbar('Successfully synchronized', { variant: 'success' }),
      )
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }))
      .finally(() => setSyncing(false));
  };

  const handleCheckCredentials = async () => {
    const isValid = await verifyCredentials(id);
    setCheckCredsColor(isValid ? greenColor : redColor);
    setTimeout(function() {
      setCheckCredsColor(grayColor);
    }, 5000);
  };

  const onMount = useCallback(() => {
    setLoading(true);

    Promise.all([getData()]).then(([dataResponse]) => {
      setData(dataResponse);
      setLoading(false);
    });
  }, [getData]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  useEffect(() => {
    dispatch(actions.setCrmType({ crmType: data?.crmType, crmId: id }));
  }, [data, dispatch, id]);

  useEffect(() => {
    if (data) {
      setCrmType(data.crmType);
    }
  }, [data, setCrmType]);

  if (loading) return <Loading />;
  return (
    <div className={classes.container}>
      <Form
        onSubmit={submit}
        validate={values => getValidation(values, data.crmType)}
        initialValues={getInitialValues(data, data.crmType)}
        render={({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit} noValidate>
            <div className={classes.formContentWrapper}>
              <Tooltip title="Check credentials" interactive>
                <div
                  role="presentation"
                  onClick={handleCheckCredentials}
                  className={cx(
                    classes.connectionStatusWrapper,
                    classes.circle,
                  )}
                  style={{ backgroundColor: checkCredsColor }}
                />
              </Tooltip>
              {allowDelete && (
                <ActionButton
                  icon={<img src={iconDelete} alt="iconDelete" />}
                  handler={handleDeleteClick}
                  toolTip="Delete integration"
                  disabled={processing || syncing}
                  classNameWrapper={classes.deleteButtonWrapper}
                />
              )}
              <div className={classes.imageWrapper}>
                <img
                  className={classes.imageLogo}
                  src={getCrmImage(data.crmType)}
                  alt=""
                />
              </div>
              <Typography className={classes.inputLabel}>
                Integration name:
              </Typography>
              <Field
                id="name"
                name="name"
                component={Input}
                classNameWrapper={classes.inputWrapper}
                styleType="main"
                inputView="text"
                fullWidth
                placeholder="Integration name"
                disabled={processing || syncing}
              />
              <FormSelector
                form={form}
                crmType={data.crmType}
                loading={processing}
                classes={classes}
                useLabels
              />
            </div>
            <div className={classes.buttonWrapper}>
              {allowUpdate && !crmsWithSyncModal.includes(data?.crmType) && (
                <>
                  <ReusableButton
                    onClick={() => {
                      syncCompaniesSubmitHandler({
                        types: [],
                        statuses: [],
                      });
                    }}
                    size="xl"
                    classNameWrapper={classes.button}
                    disabled={processing || syncing}
                    loading={syncing}
                    label="Sync Companies"
                  />
                  <ReusableButton
                    onClick={syncUsersHandler}
                    size="xl"
                    classNameWrapper={classes.button}
                    disabled={processing || syncing}
                    loading={syncing}
                    label="Sync Users"
                  />
                </>
              )}

              {allowUpdate && crmsWithSyncModal.includes(data?.crmType) && (
                <>
                  <ReusableButton
                    label="Sync Data"
                    onClick={() => {
                      setOpenPsaSyncDialog(true);
                    }}
                    size="lg"
                    disabled={processing || syncing}
                    loading={syncing}
                  />
                </>
              )}
            </div>
            <div className={classes.buttonWrapper}>
              {allowUpdate && (
                <ReusableButton
                  type="submit"
                  size="md"
                  viewType="black"
                  classNameWrapper={classes.button}
                  disabled={processing || syncing}
                  loading={processing}
                  label="resources.buttons.submit"
                />
              )}
            </div>
          </form>
        )}
      />
      <ConfirmDialog
        open={!!deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        onAccept={onDeleteHandler}
        onCancel={onCancelDeleteHandler}
        title={translate('resources.integrations.update.deleteTitle')}
        content={translate('resources.integrations.update.deleteContent')}
      />

      {openPsaSyncDialog && (
        <PsaSyncDialog
          crmId={id}
          onClose={() => {
            setOpenPsaSyncDialog(false);
          }}
        />
      )}
    </div>
  );
};

UpdateForm.propTypes = {
  id: PropTypes.string.isRequired,
  setCrmType: PropTypes.func,
};

export default UpdateForm;
