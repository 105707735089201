import {
  phoneWithCharactersValidator,
  requiredValidator,
} from '@utils/validators';

export const helpdeskAIValidation = values => ({
  name: requiredValidator(values.name),
  agentName: requiredValidator(values.agentName),
  companyName: requiredValidator(values.companyName),
  incomingPhoneNumber: phoneWithCharactersValidator(
    values.incomingPhoneNumber,
    9,
    true,
  ),
  crmTicketDefaultId: requiredValidator(values.crmTicketDefaultId),
  language: requiredValidator(values.language, true),
  incomingPhoneNumberCountryCode: requiredValidator(
    values.incomingPhoneNumberCountryCode,
  ),
  callTransferPhoneNumberCountryCode: requiredValidator(
    values.callTransferPhoneNumberCountryCode,
  ),
  callTransferPhoneNumber: phoneWithCharactersValidator(
    values.callTransferPhoneNumber,
    9,
    true,
  ),
});

export default {};
