import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { ThunkDeleteHelpdeskConfiguration } from '@store/slices/helpdeskAI/thunks';
import { actions } from '@store/actions';
import { listSelector } from '@store/selectors';

import ActionFieldItem from '@common/ActionButtons/ActionFieldItem';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import EditHelpdeskConfiguration from '@ui/pages/portalSettings/HelpdeskAI/components/EditHelpdeskConfiguration';

import DeleteIcon from '@assets/icons/delete.svg';
import EditIcon from '@assets/icons/edit.svg';

import useStyles from '../styles';

const HelpdeskTableActions = ({ row }) => {
  const [processing, setProcessing] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);

  const classes = useStyles();
  const dispatch = useDispatch();
  const list = useSelector(listSelector);

  const handleDelete = () => {
    setProcessing(true);
    dispatch(ThunkDeleteHelpdeskConfiguration(row.id))
      .unwrap()
      .then(() => {
        setOpenDeleteDialog(false);
        dispatch(actions.saveList(list.filter(item => item.id !== row.id)));
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  return (
    <div className={classes.alignCenter}>
      <ActionFieldItem
        handler={() => {
          setOpenEditDialog(true);
        }}
        toolTip="Edit"
        icon={EditIcon}
      />

      <ActionFieldItem
        handler={() => {
          setOpenDeleteDialog(true);
        }}
        toolTip="Delete"
        icon={DeleteIcon}
      />

      {openDeleteDialog && (
        <ConfirmDialog
          open
          onAccept={handleDelete}
          title="Confirm Action"
          onCancel={() => {
            setOpenDeleteDialog(false);
          }}
          content="Are you sure you want to delete this item?"
          loading={processing}
        />
      )}

      {openEditDialog && (
        <EditHelpdeskConfiguration
          open
          onClose={() => {
            setOpenEditDialog(false);
          }}
          data={row}
        />
      )}
    </div>
  );
};

HelpdeskTableActions.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

export default HelpdeskTableActions;
