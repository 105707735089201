import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { PSA_CONTACTS } from '@services/requests/requestResources';
import { ThunkGetConnectWiseIntegrationCompanies } from '@store/slices/integration/thunks';

import { getConnectWiseTableColumns } from '@components/PsaContacts/constants';

import Table from '@components/Table';
import PsaContactsTableBulkActions from '@components/PsaContacts/components/PsaContactsTableBulkActions';

import crmSources from '@constants/crmSources';

const ConnectwiseContacts = ({ psaId }) => {
  const resource = useMemo(() => `${PSA_CONTACTS}/connectwise/${psaId}`, [
    psaId,
  ]);

  return (
    <Table
      resource={resource}
      columns={getConnectWiseTableColumns(psaId)}
      bulkActions={
        <PsaContactsTableBulkActions
          psaId={psaId}
          psaName={crmSources.ConnectWise.name}
        />
      }
    />
  );
};

ConnectwiseContacts.propTypes = {
  psaId: PropTypes.number.isRequired,
};

export default ConnectwiseContacts;
